$break-small: 767px;

body {
  color: #333;
  background-color: #fff;
  line-height: 1.6;
  display: flex;
  flex-direction: column;
  height: 100%;
}

html {
  margin: 0;
  padding: 0;
  font-size: 16px;
}
