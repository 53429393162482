.normalPrice {
  letter-spacing: -1px;
  white-space: nowrap;
  display: flex;
  margin: 2px;
  font-weight: 700;
  box-sizing: inherit;
  font-size: 1rem;
  line-height: 1;
  -webkit-box-direction: normal;
  color: #111 !important;
}
.familyPrice {
  //color: #cf4319 !important;
  margin: 2px;
  letter-spacing: -1px;
  display: flex;
  white-space: nowrap;
  box-sizing: inherit;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1;
  // span {
  //   color: #cf4319 !important;
  // }
}

.oldPrice {
  text-decoration: line-through;
  color: #cccccc;
  margin: 2px;
  letter-spacing: -1px;
  display: flex;
  white-space: nowrap;
  box-sizing: inherit;
  font-weight: 700;
  font-size: 14px;
  line-height: 1;
}

.restrictedPrice {
  color: #cf4319;
  margin: 2px;
  letter-spacing: -1px;
  display: flex;
  white-space: nowrap;
  box-sizing: inherit;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1;
}
.ikeaFamilyTitle {
  font-size: 1rem;
  line-height: 1.5;
}
.pricePanel {
  display: flex;
  flex-direction: column;
}
.breathtakingLabel {
  background: #ffce00;
  padding: 0.05rem 0.2rem;
  margin-bottom: 0.4rem;
  margin-top: 0.2rem;
  width: fit-content;

  align-self: flex-start;
  -webkit-box-shadow: 4px 4px 0 0 #ef4123;
  box-shadow: 4px 4px 0 0 #ef4123;
  @media (max-width: 374px) {
    margin-bottom: 15px !important;
  }
}
.breathtakingPrice {
  display: inline-block;
  font-weight: 700;
  font-size: 2rem;
  line-height: 1;
}

.actualPrice {
  font-weight: normal;
  font-size: 14px;
  padding-top: 5px;
}
