.menu {
  display: flex;
  justify-content: flex-start;

  @media only screen and (min-width: 36rem) {
    justify-content: flex-start;
  }

  @media only screen and (min-width: 62rem) {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &__ose {
    margin-top: auto;

    &__pop-up {
      // position: unset;

      @media only screen and (min-width: 600px) and (orientation: landscape) {
        left: 15px;
        position: absolute;
        bottom: 40px;
      }

      @media only screen and (max-width: 1199px) and (orientation: landscape) {
        bottom: 75px;
        max-width: 40px;
      }
    }
  }
  &__data {
    min-width: 150px;
    padding: 10px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0px 0px 3px 0px #bcbcbc;
    position: absolute;
    bottom: 50px;
    @media only screen and (max-width: 599px) {
      bottom: 70px;
      right: 20px;
    }
    &:after {
      content: "";
      display: block;
      width: 15px;
      height: 15px;
      background: #fff;
      position: absolute;
      bottom: -8px;
      left: calc(50% - 8px);
      @media only screen and (max-width: 1199px) {
        left: 15px;
      }
      border: 1px solid #e6e6e6;
      transform: rotate(45deg);
      border-width: 0 1px 1px 0;
      @media only screen and (max-width: 599px) {
        right: 40px;
        left: unset;
      }
    }
  }
  &__link {
    display: flex;
    text-decoration: none;
    align-items: center;
    height: 2.5rem;
    width: 2.5rem;
    line-height: 2.5rem;
    // margin: 0 1.25rem;
    position: relative;

    @media only screen and (min-width: 36rem) {
      width: auto;
    }

    @media only screen and (min-width: 62rem) {
      // margin: 0 0 0.5rem 0;
    }

    &--btn {
      background: none;
      border: none;
      padding: 0;
    }

    &__arrow {
      width: 2.5rem;
      height: 2.5rem;
      transition: opacity 300ms ease;
      background: #ffcd3f;
      opacity: 0;
      border-radius: 50%;
    }

    &__badge {
      width: 2.5rem;
      height: 2.5rem;
      position: absolute;

      .badge {
        left: auto;
        right: -0.75rem;
        top: 0.125rem;
      }
    }

    &.active {
      font-weight: 700;

      .menu__link__arrow {
        opacity: 1;
      }

      .badge {
        background: #111;
        color: white;
      }
    }
  }

  &__icon {
    width: 2.5rem;
    position: absolute;

    &--ose {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    }
  }

  &__svg {
    display: block;
    height: 1rem;
    width: 1rem;
    margin: 0 auto;
  }

  &__label {
    flex-grow: 1;
    font-size: 14px;
    padding-left: 1rem;
    color: #111;
    display: none;

    @media only screen and (min-width: 1200px) {
      display: block;
    }
  }
}
