.cart-icon-wrapper {
  position: relative;
}

.badge {
  width: 21px;
  height: 21px;
  line-height: 20px;
  span {
    width: 100%;
  }

  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;

  position: absolute;
  top: -4px;
  right: -5px;
  background: #0058a3;
  color: transparent;
  border-radius: 20px;
  animation-duration: 2s;
  animation-iteration-count: 1;
  animation-play-state: initial;

  transition: ease 0.3s width, ease 0.3s height, ease 0.3s top, ease 0.3s right;
  transform: scale(0.6);

  &--pre-bounce {
    width: 21px;
    height: 21px;
  }

  &--bounce {
    animation-name: badge-bounce;
    animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
  }

  &--empty {
    width: 0px;
    height: 0px;
    top: 6px;
    right: 3px;
  }
}

@keyframes badge-bounce {
  0% {
    transform: scale(0.6, 0.6) translateY(0);
    color: transparent;
  }
  10% {
    transform: scale(1.03, 0.97) translateY(0);
  }
  20% {
    transform: scale(0.97, 1.08) translateY(-7px);
  }
  30% {
    transform: scale(1.03, 0.97) translateY(0);
  }
  34% {
    transform: scale(1, 1) translateY(-2px);
    color: transparent;
  }

  37% {
    color: #fff;
    transform: scale(1, 1) translateY(0);
  }

  85% {
    color: #fff;
    transform: scale(1, 1) translateY(0);
  }

  100% {
    color: transparent;
    transform: scale(0.6, 0.6) translateY(0);
  }
}
