.App {
  width: 100%;
  height: 100%;
  min-height: 100%;
}

main {
  width: 100%;
  min-height: calc(100%);
  @media only screen and (max-width: 1023px) {
    min-height: calc(100%);
  }
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-link {
  color: #61dafb;
}

.chairLoading {
  background-image: url("./static/loading-animation.gif");
  background-repeat: no-repeat;
  background-size: fit;
  background-position-x: calc(50% - 22px);
  background-position-y: center;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 10001 !important;
  background-color: rgba(255, 255, 255, 0.9);
  @media only screen and (max-width: 567px) {
    background-position-x: calc(50%);
  }
}
.emptyBag {
  background-image: url("./static/emptybag.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
  background-position-y: center;
  width: 100%;
  height: 100%;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

body .main {
  flex: 1 0 auto;
}

.update-notification {
  position: absolute;
  bottom: 0;
  right: 0;
  text-decoration: none;
  text-align: left;
}

img {
  background-image: url("./static/loading.svg");
  background-repeat: no-repeat;
  background-size: fit;
  background-position-x: center;
  background-position-y: center;
  background-size: 50%;
}

.modal {
  padding-top: calc(env(safe-area-inset-top));
}

//MOVE TO SEPERATE

.searchInput {
  float: left;
  width: 60%;
  max-height: 10px;
}

.searchInput input {
  padding: 0.8rem 0.7rem 0.3375rem !important;
}

.react-autosuggest__container {
  position: relative;
  float: right;
  width: 100%;
  max-height: 10px;
  z-index: 100 !important;
  color: black;
}

.react-autosuggest__input {
  background-size: 24px 24px;
  background-position-x: 24px;
  background-position-y: 18px;
  background-image: url("../src/static/search-thin-24.svg");
  background-repeat: no-repeat;
}
.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  background-color: #fff;
  font-family: inherit;
  font-weight: 300;
  font-size: 16px;
  border: none;
  z-index: 2;
  max-height: 330px;
  width: 100%;
  overflow-y: auto;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  li {
    padding: 11px 5px;
    line-height: 20px;
    transition: cubic-bezier(0.25, 0.1, 0.25, 1) background 0.3s;
    &:hover {
      background: #f5f5f5;
    }
  }
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 4px 0px 2px 20px;
  text-align: left;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.react-autosuggest__container input {
  padding: 0.8rem 0.7rem 0.3375rem;
}

input::placeholder {
  color: #cccccc !important;
}

/* Animated */

.search-suggestions {
  position: absolute;
  top: 50%;
  padding-left: 12px;
  padding-top: 10px;
  color: #000;
  pointer-events: none;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  @media only screen and (max-width: 1024px) {
    top: 81%;
  }
}

.search-suggestions__list {
  display: inline-block;
  padding: 0;
  margin: 0;
  list-style: none;
}

.search-suggestions__item {
  display: none;
  padding-left: 5px;
}

.search-suggestions__item--delayed.search-suggestions__item--active {
  display: inline-block;
  opacity: 1;
  -webkit-animation: fade-out 1.05s ease 3.95s;
  animation: fade-out 1.05s ease 3.95s;
}

.search-suggestions__item--active:not(.search-suggestions__item--delayed) {
  display: inline-block;
  -webkit-animation: fade-in-out 3s ease;
  animation: fade-in-out 3s ease;
}

.animatedSection {
  background: white;
  height: 43px;
  border-radius: 4px;
}
.animatedSection input {
  background-color: transparent;
}

@-webkit-keyframes fade-in-out {
  0% {
    opacity: 0;
  }

  25%,
  65% {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes fade-in-out {
  0% {
    opacity: 0;
  }

  25%,
  65% {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@-webkit-keyframes fade-out {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

div[role="button"] {
  cursor: pointer;
}
